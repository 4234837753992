import axios from 'axios';

import { Org, Exam } from './types'

const EXAMS_GET = `https://qaiiggk2fsi376gvxfbzqktnqe0ritzb.lambda-url.us-east-1.on.aws`;
const EXAMS_PUT = `https://oz4zl3ixlmsi7ewtco4kzylbz40dneyp.lambda-url.us-east-1.on.aws`;

const headers = {
  'Content-Type': 'text/plain',
  'Accept': '*/*'
}
class ExamsApi {

  public getExams = async (exam:Exam): Promise<any> => {
    try {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.post(`${EXAMS_GET}`,exam, { headers });
    } catch (e) {
      throw e;
    }
  }

  public getOne = async (exam:Exam): Promise<any> => {
    try {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.get(`${EXAMS_GET}/${exam.Id}`, { headers });
    } catch (e) {
      throw e;
    }
  }

  public put = async (exam:Exam): Promise<any> => {
    try {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.put(`${EXAMS_PUT}`,exam, { headers });
    } catch (e) {
      throw e;
    }
  }

  public patch = async (exam:Exam): Promise<any> => {
    try {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.put(`${EXAMS_PUT}/${exam.Id}`,exam, { headers });
    } catch (e) {
      throw e;
    }
  }

  public delete = async (exam:Exam): Promise<any> => {
    try {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      return axios.put(`${EXAMS_PUT}/${exam.Id}`,exam, { headers });
    } catch (e) {
      throw e;
    }
  }

}
export default ExamsApi;