import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OpenInNew } from '@material-ui/icons';
import { AccountBookOutlined, HomeOutlined, MedicineBoxOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Button } from 'antd';

import { Carousel } from 'antd';
import ReactPlayer from 'react-player'

import ExamsApi from './api';
import { Exam, ExamFile } from './types';
import logo from '../../images/logo.png'

import './index.css';

const translate = (key: string) => {
  if (key === 'home.logout') {
      return 'Logout';
  }
  if (key == 'home.privacy') {
    return 'Privacy Policy';
  }
  if (key == 'home.terms') {
      return 'Terms of Use';
  }
}

const contentStyle: React.CSSProperties = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const MainPage = () => {

  const [error, setError] = useState('');
  const [isSubmitting] = useState(false);
  const navigate = useNavigate();
  const [page] = useState(1);
  const [exams, setExams] = useState<Exam[]>([]);
  let exam:Exam = {
    OrgId: "b9ccb162-d986-4cdb-b1b0-c362bd529d8a",
    SiteId: "035848db-9a0d-4098-b96e-ca812f0f4de8",
    PatientId: "4aea665e-bdf1-4129-a336-5cd955ae8aa1",
    ExamId: "f10f8058-7576-4d1d-b2f3-9f030f950e2c"
  }
  const [videourl, setVideoUrl] = useState('');
  const [photos, setPhotos] = useState<string[]>([]);

  const submitLogout = async () => {
    try {
      localStorage.setItem('OrgId', '');
      localStorage.setItem('token', '');
      navigate(`/`);
    } catch (err) {
      throw Error('Unable to submit logout');
    }
  };

  const setupPhotos = (exam: Exam) => {
    let len = 0;
    let files: ExamFile[] = [];
    if (exam.ExamFiles && exam.ExamFiles.length > 0) {
      files = exam.ExamFiles;
      len = exam.ExamFiles.length;
    }
    for (var i = 0; i < len; i++) {
      var ef = files[i];
      //skip first - video.
      if (i > 0 && (ef)) {
        photos.push(ef.ExamFileURL);
      }
    }
    setPhotos(photos);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await new ExamsApi().getExams(exam);
      setExams(response.data.exams);
      setVideoUrl(response.data.exams[0].ExamFiles[0].ExamFileURL);
      setupPhotos(response.data.exams[0]);
    }
    fetchData()
    .catch(console.error);
  }, [page]);

  return (
    <div className="wrapper">
      <div className="header">
        <div>
          <img src={logo} />
        </div>
        <div>
        <Button onClick={() => submitLogout()}>
          {translate('home.logout')}
        </Button>
        </div>
      </div>
      <div className="body">
        <div className="contentWrapper">
          <div className="content">
          <Breadcrumb
          items={[
            {
              href: '',
              title: (
                <>
                  <HomeOutlined />
                  <span>Pristine Surgical</span>
                </>
              ),
            },
            {
              href: '',
              title: (
                <>
                  <MedicineBoxOutlined />
                  <span>Global</span>
                </>
              ),
            },
            {
              href: '',
              title: (
                <>
                  <UserOutlined />
                  <span>Patient</span>
                </>
              ),
            },
            {
              href: '',
              title: (
                <>
                  <AccountBookOutlined />
                  <span>Exam</span>
                </>
              ),
            }
          ]}
        />
        <div>
          {
            <React.Fragment key={"1"}>
                <div className='player-wrapper'>
                    <video src={videourl||''} preload="auto"></video>
                </div>
            </React.Fragment>
          }
        </div>
        <Carousel autoplay>
        <div>
          <img src={photos[0]}/>
        </div>
        <div>
          <img src={photos[1]}/>
        </div>
        <div>
          <img src={photos[2]}/>
        </div>
      </Carousel>
          </div>
        </div>
        <div className="footer">
          <br />
          <br />
          <div className="footer">
          <span>© examsreview.com 2024. All rights reserved. </span>
          <br />
          </div>
          <br />
          <Button href="/terms-of-use.html">
              <OpenInNew style={{ paddingRight: '0.5em' }} />
              {translate('home.terms')}
          </Button>
          <Button href="/privacy-policy.html">
              <OpenInNew style={{ paddingRight: '0.5em' }} />
              {translate('home.privacy')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
