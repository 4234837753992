import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, User } from './types';
import logo from '../../../images/logo.png';
import LoginApi from './api';
import './index.css';

const formTemplate = [
  {
    label: 'Email',
    name: 'Email',
    type: 'text',
    isRequired: true,
    error: '',
    value: ''
  },
  {
    label: 'Password',
    name: 'Password',
    type: 'password',
    isRequired: true,
    error: '',
    value: ''
  }
];

async function postLogin(user: User) {
  return await new LoginApi().login(user);
}

function moreThanOneHundredChars(text: string) {
  return text.length > 100;
}

function moreThanOneChar(text: string) {
  return text.length === 0;
}

const LoginPage = () => {
  const [formData, setFormData] = useState<Input[]>(
    formTemplate
  );
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const updateForm = (value: string, index: number) => {
    const freshForm = [...formData];
    freshForm[index].value = value;
    setFormData(freshForm);
  };

  async function validateForm(form: Input[]) {
    const freshForm = [...formData];
    await Promise.all(
      form.map(async (singleInput, i) => {
        if (moreThanOneHundredChars(singleInput.value)) {
          freshForm[i].error = 'Must be less than 100 characters.';
          throw Error('Must be less than 100 characters.');
        }
        if (singleInput.isRequired && moreThanOneChar(singleInput.value)) {
          freshForm[i].error = 'Required field.';
          throw Error('Required Field');
        }
        return true;
      })
    );

    return form.reduce(
      (responseObj: any, input: Input) => {
        const name = input.name;
        return Object.assign(responseObj, { [name]: input.value });
      },
      {}
    );
  }

  const submitLogin = async () => {
    try {
      const formInputs = await validateForm(formData);
      const response = await postLogin({
        ...formInputs
      });
      localStorage.setItem('OrgId', response.data.OrgId);
      localStorage.setItem('token', response.data.token);
      navigate(`/exams`);
    } catch (err) {
      navigate(`/exams`);
      setError('Error');
      throw Error('Unable to submit login');
    }
  };

  return (
    <div className="registrationWrapper">
      <div className="registrationBody">
        <div className="registrationContentWrapper">

          <div className="registrationContent">
            <div className="registrationForm">
            <div>
            <a href={'/'} tabIndex={-1} rel="noreferrer" target="_blank">
              <img aria-hidden="true" src={logo} />
            </a>
            </div>
              <div className="registrationInputs">
                {formTemplate.map((singleInput, i) => (
                  <div 
                    key={'singleInput-' + i}
                    className={`registrationSingleInput 
                    ${singleInput.error.length ? 'errored' : ''}
                    ${singleInput.value.length ? 'active' : ''}
                    `}
                  >
                    <label>{singleInput.label}</label>
                    <input
                      id={singleInput.name}
                      type={singleInput.type}
                      onChange={(evt) => updateForm(evt.target.value, i)}
                      value={singleInput.value}
                    />
                    {singleInput.error.length > 0 && (
                      <div className="inputError">
                        <span>
                          {singleInput.error}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {error && (
                <div className="errors">
                  <span>{error}</span>
                </div>
              )}
              <div className="registrationButton">
                <button className="submit" onClick={() => submitLogin()}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
